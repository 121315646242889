<div class="container-fluid top-nav">
  <div class="container">
    <div class="row">
      <div class="col-6 d-inline-flex align-items-center">
        <div class="flex ms-2">
          <a href="tel:8886192593">
          <fa-icon [icon]="['fas', 'phone']" size="lg" class="text-white"></fa-icon>
        </a>
        </div>
        <div class="flex ms-2">
          <p class="mb-0">(888) 619-2593</p>
        </div>
        <div class="d-none d-md-flex ms-2">
          |
        </div>
        <div class="d-none d-md-flex ms-2">
          <a href="https://www.facebook.com/ihmusedparts" target="_blank">
            <fa-icon [icon]="['fab', 'facebook-f']" size="lg" class="text-white"></fa-icon>
          </a>
        </div>
      </div>
      <div class="col-6 d-inline-flex justify-content-end">
        <div class="flex ms-2 cursor-pointer">
          <fa-icon [icon]="['fas', 'search']" size="lg" class="text-white"
            [ngClass]="showSearchBar ? 'active-search' : ''" (click)="toggleSearchBar()"></fa-icon>
        </div>
        <div class="flex ms-2 cursor-pointer" [routerLink]="['cart']">
          <fa-icon [icon]="['fas', 'shopping-cart']" size="lg" class="text-white"></fa-icon>
          <div class="bg-circle text-center" *ngIf="orderItemCount && orderItemCount !== 0">
            <p class="text-white">{{orderItemCount}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<nav class="navbar navbar-expand-lg main-nav navbar-light bg-light">
  <div class="container">
    <a class="navbar-brand" [routerLink]="['home']">
      <img src="assets/images/ihm-used-parts-logo@2x.webp" alt="IHM Used Parts Home" width="129px" height="auto"
        loading="lazy" />
    </a>
    <button class="navbar-toggler" type="button" (click)="toggleSideNav()" aria-controls="navbarTogglerDemo02"
      aria-expanded="false">
      <fa-icon [icon]="['fas', 'bars']" size="lg" class="mt-2 text-ihm"></fa-icon>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto">
        <li *ngFor="let route of routes" class="nav-item icon active d-block">
          <a class="nav-link" [routerLink]="route.url" routerLinkActive="active">
            {{route.txt}}
          </a>
        </li>
        <li class="nav-item icon active d-block" *ngIf="currentUser">
          <a class="nav-link" routerLink="notifications" routerLinkActive="active">
            Notifications
          </a>
        </li>
        <li class="nav-item icon active d-block">
          <a class="nav-link">
            |
          </a>
        </li>
        <li class="nav-item icon active d-block">
          <a *ngIf="!currentUser" class="nav-link d-inline-flex" [routerLink]="['login']" routerLinkActive="active">
            <div class="flex">Log In</div>
            <fa-icon [icon]="['fas', 'sign-in-alt']" size="lg" class="flex ms-2"></fa-icon>
          </a>
          <a *ngIf="currentUser" class="nav-link d-inline-flex" routerLinkActive="active" ngbDropdown
            placement="bottom-left">
            <div class="d-inline-block" *ngIf="!showAdmin">
              <div class="d-inline-flex" ngbDropdownToggle id="dropdownBasic1">
                <div class="flex">
                  Account Settings
                </div>
                <fa-icon [icon]="['fas', 'cog']" size="lg" class="flex ms-2">
                </fa-icon>
              </div>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="ps-2 pe-2">
                <button ngbDropdownItem [routerLink]="['/account']" [queryParams]="{tab: 'profile'}"
                  class="navbar-btn border-bottom">User Profile</button>
                <button ngbDropdownItem [routerLink]="['/account']" [queryParams]="{tab: 'billing'}"
                  class="navbar-btn border-bottom">Billing Options</button>
                <button ngbDropdownItem [routerLink]="['/account']" [queryParams]="{tab: 'order'}"
                  class="navbar-btn">Order History</button>
              </div>
            </div>
            <div class="d-inline-block" *ngIf="showAdmin && dashboard === 'user'">
              <div class="d-inline-flex" routerLink="active" *ngIf="currentUser.role === 'admin'" [routerLink]="['/customer-support/home']">
                <div class="flex">
                  Admin Dashboard
                </div>
                <fa-icon [icon]="['fas', 'cog']" size="lg" class="flex ms-2">
                </fa-icon>
              </div>
              <div class="d-inline-flex" routerLink="active" *ngIf="currentUser.role === 'super_admin'" [routerLink]="['/super-admin/home']">
                <div class="flex">
                  Super Admin Dashboard
                </div>
                <fa-icon [icon]="['fas', 'cog']" size="lg" class="flex ms-2">
                </fa-icon>
              </div>
            </div>
            <div class="d-inline-block" [routerLink]="['']" *ngIf="showAdmin && dashboard === 'admin' || showAdmin && dashboard === 'super_admin'">
              <div class="d-inline-flex">
                <div class="flex">
                  IHM Homepage
                </div>
                <fa-icon [icon]="['far', 'window-maximize']" size="lg" class="flex ms-2">
                </fa-icon>
              </div>
            </div>
          </a>
          <a *ngIf="currentUser" class="nav-link d-inline-flex" (click)="signOut()">
            <div class="flex">Sign Out</div>
            <fa-icon [icon]="['fas', 'sign-out-alt']" size="lg" class="flex ms-2"></fa-icon>
          </a>
        </li>
      </ul>
    </div>
    <div *ngIf="showSideNav" class="navbar-collapse my-offcanvas" [ngClass]="!showSideNav ? '' : 'show'"
      id="navbarSupportedContent2">
      <ul class="navbar-nav ml-auto" *ngIf="!currentUser">
        <li class="nav-item small active mt-2">
          <div class="w-100 d-inline-flex">
            <div class="flex ms-auto mr-3 mt-2" (click)="toggleSideNav()">
              <fa-icon [icon]="['fas', 'window-close']" size="lg" class="text-ihm"></fa-icon>
            </div>
          </div>
        </li>
        <li class="nav-item small active mt-2">
          <a class="nav-link text-uppercase" [routerLink]="['sign-up']">
            Sign up
          </a>
        </li>
        <li class="nav-item small active mt-2">
          <a class="nav-link text-uppercase" [routerLink]="['login']">
            Log In
          </a>
        </li>
        <li class="nav-item small active mt-2">
          <a class="nav-link text-uppercase" [routerLink]="['shop']" routerLinkActive="active">
            Shop Parts
          </a>
        </li>
        <li class="nav-item small active mt-2">
          <a class="nav-link text-uppercase" [routerLink]="['sell']" routerLinkActive="active">
            Sell
          </a>
        </li>
        <li class="nav-item small active mt-2">
          <a class="nav-link text-uppercase" [routerLink]="['blog']" routerLinkActive="active">
            Blog
          </a>
        </li>
        <li class="nav-item small active mt-2">
          <a class="nav-link text-uppercase" [routerLink]="['contact']" routerLinkActive="active">
            Contact
          </a>
        </li>
        <li class="nav-item small active mt-2" *ngIf="currentUser">
          <a class="nav-link text-uppercase" [routerLink]="['notifications']" routerLinkActive="active">
            Notifications
          </a>
        </li>
      </ul>
      <ul class="navbar-nav ml-auto" *ngIf="currentUser">
        <li class="nav-item small active mt-2">
          <div class="w-100 d-inline-flex">
            <div class="flex ms-auto mr-3 mt-2" (click)="toggleSideNav()">
              <fa-icon [icon]="['fas', 'window-close']" size="lg" class="text-ihm"></fa-icon>
            </div>
          </div>
        </li>
        <li class="nav-item small active mt-2" *ngIf="currentUser.role === 'super_admin'">
          <a class="nav-link text-uppercase" [routerLink]="['/super-admin/home']">
            Super Admin Dashboard
          </a>
        </li>
        <li class="nav-item small active mt-2" *ngIf="currentUser.role === 'admin'">
          <a class="nav-link text-uppercase" [routerLink]="['/customer-support/home']">
            Admin Dashboard
          </a>
        </li>
        <li class="nav-item small active mt-2">
          <a class="nav-link text-uppercase" [routerLink]="['shop']" routerLinkActive="active">
            Shop Parts
          </a>
        </li>
        <li class="nav-item small active mt-2">
          <a class="nav-link text-uppercase" [routerLink]="['sell']" routerLinkActive="active">
            Sell
          </a>
        </li>
        <li class="nav-item small active mt-2">
          <a class="nav-link text-uppercase" [routerLink]="['blog']" routerLinkActive="active">
            Blog
          </a>
        </li>
        <li class="nav-item small active mt-2">
          <a class="nav-link text-uppercase" [routerLink]="['contact']" routerLinkActive="active">
            Contact
          </a>
        </li>
        <li *ngIf="currentUser" class="nav-item small active mt-2">
          <a class="nav-link text-uppercase" [routerLink]="['notifications']" routerLinkActive="active">
            Notifications
          </a>
        </li>
        <li class="nav-item small active mt-2">
          <a class="nav-link text-uppercase" [routerLink]="['account']" routerLinkActive="active">
            Account
          </a>
        </li>
        <li class="nav-item small active mt-2">
          <a class="nav-link text-uppercase" (click)="signOut()">
            Logout
          </a>
        </li>
      </ul>
    </div>
    <div *ngIf="showSearchBar" class="navbar-collapse my-offcanvas searchbar" [ngClass]="!showSearchBar ? '' : 'show'"
      id="navbarSupportedContent3">
      <app-searchbar (closeSearch)="closeSearchBar()"></app-searchbar>
    </div>
  </div>
</nav>
