<form class="form row" [formGroup]="form">
  <div class="col-12 ps-1 ps-md-4 pe-1 pe-md-4 pt-2 pb-4">
    <input formControlName="searchValue" class="form-control-lg w-100 ihm-checkbox mt-0 text" type="text" placeholder="Search Parts by Make, Model, or Part #">
  </div>
  <div class="col-6 ps-1 ps-md-4 pe-1 pe-md-4">
    <div class="form-check">
      <input formControlName="newSurplus" class="form-check-input ihm-checkbox checkbox" type="checkbox"  (click)="setOtherTypesToFalse('newSurplus')">
      <label class="form-check-label">NEW - SURPLUS</label>
    </div>
  </div>
  <div class="col-6 ps-1 ps-md-4 pe-1 pe-md-4">
    <div class="form-check">
      <input formControlName="newAftermarket" class="form-check-input ihm-checkbox checkbox" type="checkbox"  (click)="setOtherTypesToFalse('newAftermarket')">
      <label class="form-check-label" >NEW - AFTERMARKET</label>
    </div>
  </div>
  <div class="col-6 ps-1 ps-md-4 pe-1 pe-md-4">
    <div class="form-check">
      <input formControlName="used" class="form-check-input ihm-checkbox checkbox" type="checkbox" (click)="setOtherTypesToFalse('used')"
        aria-label="Checkbox for following text input">
      <label class="form-check-label">USED</label>
    </div>
  </div>
  <div class="col-6 ps-1 ps-md-4 pe-1 pe-md-4">
    <div class="form-check">
      <input formControlName="reManufactured" class="form-check-input ihm-checkbox checkbox" type="checkbox" (click)="setOtherTypesToFalse('reManufactured')">
      <label class="form-check-label">RE-MANUFACTURED</label>
    </div>
  </div>
  <div class="col-12 d-grid ps-1 ps-md-4 pe-1 pe-md-4 pt-4 pb-4">
    <button class="btn btn-outline-primary search-btn" (click)="submitForm()">
      Start Search
      <fa-icon *ngIf="submitting" [icon]="['fas', 'spinner']" size="lg" spin="true"
      class="ms-2 text-white"></fa-icon>
    </button>
  </div>
</form>
