import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MetaTagsService } from './shared/services/meta-tags.service';
declare var gtag: any;
declare var fbq: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'ihm-web';
  private subs = new Subscription()
  dashboard = 'user'
  constructor(
    private router: Router,
    private metaService: Meta,
    private domSanitizer: DomSanitizer,
    private tagsService: MetaTagsService,
    private renderer: Renderer2, @Inject(DOCUMENT) private _document: any,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) //Client side execution
    {
      this.injectScripts();
    }
  }


  injectScripts() {
    // first
    const gtmScriptTag = this.renderer.createElement('script');
    gtmScriptTag.type = 'text/javascript';
    gtmScriptTag.src = 'https://www.googletagmanager.com/gtag/js?id=G-Z9EMC79J13';
    this.renderer.appendChild(this._document.body, gtmScriptTag);
    // second
    const gtagInitScript = this.renderer.createElement('script');
    gtagInitScript.type = 'text/javascript';
    gtagInitScript.text = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-Z9EMC79J13');
    `;
    this.renderer.appendChild(this._document.body, gtagInitScript);

    const gaScript = this.renderer.createElement('script');
    gaScript.type = 'text/javascript';

  }

  ngOnInit() {
    this.subs.add(
      this.router.events.subscribe((y: NavigationEnd) => {
        if (y instanceof NavigationEnd) {
          if (isPlatformBrowser(this.platformId)) {
            gtag('config', 'G-Z9EMC79J13', { 'page_path': y.url });
          }
          const url = environment.webUrl + y.url
          const superAdmin = y.url.includes('super-admin')
          const admin = y.url.includes('customer-support')
          if (superAdmin) {
            this.dashboard = 'super_admin'
          } else if (admin) {
            this.dashboard = 'admin'
          } else {
            this.dashboard = 'user'
          }
          const tags = [
            { property: 'og:url', content: url }
          ]
          tags.forEach(x => {
            this.metaService.updateTag(x)
          })
        }
      })
    )
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }
}
