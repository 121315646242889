import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class MetaTagsService {
  private currentTagsSubject: BehaviorSubject<any[]>
  public currentTags: Observable<any[]>
  constructor(
    private storage: LocalStorageService
  ) {
    this.currentTagsSubject = new BehaviorSubject<any>(this.storage.getItem('currentTags'))
    this.currentTags = this.currentTagsSubject.asObservable()
  }

  public get currentTagsValue(): any[] {
    return this.currentTagsSubject.value
  }

  setCurrentTags(tags: any[]) {
    this.storage.setItem('currentTags', tags)
    this.currentTagsSubject.next(tags)
  }

}
