import { Location } from '@angular/common';
import { AfterContentInit, AfterViewInit, Component, OnChanges, OnInit, Renderer2 } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ShareService } from 'ngx-sharebuttons';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MetaTagsService } from '../../shared/services/meta-tags.service'

@Component({
  selector: 'app-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss']
})
export class SocialShareComponent implements OnInit, OnChanges, AfterViewInit, AfterContentInit {
  hideMobileShare = false
  showSmallShare = false
  tags: any[]
  image: string
  title: string
  description: string
  url: string
  showShare = false
  routeUrl: string
  private subs = new Subscription()
  constructor(
    private router: Router,
    private location: Location,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private shareService: ShareService,
    private tagsService: MetaTagsService,
  ) { }

  ngOnInit(): void {
    this.handleShareVisibility()
    this.subs.add(
      this.tagsService.currentTags.subscribe(data => {
        if (data && data.length) {
          this.buildTags(data)
        }
      })
    )
  }

  ngOnChanges() {
    this.handleShareVisibility()
  }

  handleShareVisibility() {
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.showShare = false
        this.hideMobileShare = false
        if (e && e.url) {
          const urlArr = e.url.split('/')
          let url = urlArr[1]
          this.routeUrl = url
          if (url || url === '') {
            const whiteListRoutes = [
              '',
              'home',
              'shop',
              'sell',
              'blog',
              'contact',
              'quote',
              'about',
              'outsiders'
            ]
            for (let i = 0; i < whiteListRoutes.length; i++) {
              if (whiteListRoutes[i] === url) {
                this.showShare = true
                this.hideMobileShare = false
                if (url === 'listing') {
                  this.hideMobileShare = true
                }
                break
              }
            }
          }
        }
      });
  }

  buildTags(data: any[]) {
    this.tags = data
    const ogImg = this.tags.filter(x => x['property'] === 'og:image')[0]
    if (ogImg) {
      this.image = ogImg.content
    }
    const ogTitle = this.tags.filter(x => x['property'] === 'og:title')[0]
    if (ogTitle) {
      this.title = ogTitle.content
    }
    const ogDesc = this.tags.filter(x => x['property'] === 'og:description')[0]
    if (ogDesc) {
      this.description = ogDesc.content
    }
    const ogUrl = this.tags.filter(x => x['property'] === 'og:url')[0]
    if (ogUrl) {
      this.url = ogUrl.content
    }
  }

  ngAfterViewInit() {
    this.renderer.listen('window', 'scroll', () => {
      if (this.showShare) {
        if (this.routeUrl === 'search') {
          if (window.scrollY < 2 || window.scrollY > 2000) {
            this.showSmallShare = false
          } else {
            this.showSmallShare = true
          }
        } else {
          if (window.scrollY < 300 || window.scrollY > 2000) {
            this.showSmallShare = false
          } else {
            this.showSmallShare = true
          }
        }
      } else {
        this.showSmallShare = false
      }
    })
  }

  ngAfterContentInit() {
    // const tags = this.metaService.getTag(`name='og:image'`)
  }

}
