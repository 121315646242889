import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CartService } from 'src/app/cart/cart.service';
import { User } from 'src/app/shared/models/user';
import { UserService } from 'src/app/shared/services/user.service';
import { ADMINROUTES } from './admin-routes';
import { SUPERADMINROUTES } from './super-admin-routes';
import { USERROUTES } from './user-routes';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() dashboard: string = 'user'
  showSideNav = false
  showSearchBar = false
  currentUser: User
  showAdmin = false
  orderItemCount: number // replace with subscribing to the cart state
  // routes for navbar
  routes: any[]
  userRoutes = USERROUTES
  adminRoutes = ADMINROUTES
  superAdminRoutes = SUPERADMINROUTES
  private subs = new Subscription()
  constructor(
    private userService: UserService,
    private cartService: CartService,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {
    this.currentUser = this.userService.currentUserValue
  }

  ngOnInit(): void {
    this.subToCurrentUser()
    this.subToCurrentCart()
    this.subs.add(
      this.router.events.subscribe((y: NavigationEnd) => {
        if(y instanceof NavigationEnd) {
          this.showSearchBar = false
          this.showSideNav = false
        }
      })
    )
  }

  ngOnChanges() {
    switch(this.dashboard) {
      case 'user': {
        this.routes = this.userRoutes
        break
      }
      case 'admin': {
        this.routes = this.adminRoutes
        break
      }
      case 'super_admin': {
        this.routes = this.superAdminRoutes
        break
      }
      default: {
        this.routes = this.userRoutes
        break
      }
    }
    this.cd.markForCheck()
  }

  subToCurrentUser() {
    this.subs.add(
      this.userService.currentUser.subscribe(user => {
        if (user) {
          this.currentUser = user
          if (user.role === 'admin' || user.role === 'super_admin') {
            this.showAdmin = true
          } else {
            this.showAdmin = false
          }
        } else {
          this.currentUser = null
        }
      })
    )
  }

  subToCurrentCart() {
    this.subs.add(
      this.cartService.currentCart.subscribe(cart => {
        if (cart && cart.parts && cart.parts.length) {
          this.orderItemCount = cart.parts.length
        } else {
          this.orderItemCount = 0
        }
      })
    )
  }

  toggleSideNav() {
    this.showSideNav = !this.showSideNav
  }

  toggleSearchBar() {
    this.showSearchBar = !this.showSearchBar
  }

  closeSearchBar() {
    this.showSearchBar = false
  }

  signOut() {
    this.showSideNav = false
    this.userService.logoutUser()
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }

}
