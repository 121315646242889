import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ComponentsModule } from './components/components.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthorizationHeaderService } from './shared/services/authorization-header.service';
import { DecimalPipe, TitleCasePipe } from '@angular/common';
import { SocialShareModule } from './components/social-share/social-share.module';
import { ProductModule } from './product/product.module';
import { ErrorInterceptorService } from './shared/services/error-interceptor.service';
import { IhmErrorHandler } from './error-handler';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    NgbModule,
    ComponentsModule,
    FontAwesomeModule,
    HttpClientModule,
    SocialShareModule,
    // ProductModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      // comment for commit
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationHeaderService,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    },
    { provide: ErrorHandler , useClass: IhmErrorHandler },
    TitleCasePipe,
    DecimalPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
