<footer class="footer container-fluid">
  <div class="container d-none d-md-block">
    <div class="row">
      <div class="col-12 d-inline-flex footer-col">
        <div class="flex">
          <a [routerLink]="['home']">
            <img class="img img-fluid footer-logo" loading="lazy" src="assets/images/logo-ihm-reverse@2x.webp"
              alt="IHM Used Parts Footer Logo" />
          </a>
        </div>
        <div class="flex ms-auto">
          <div class="row">
            <div class="col-12 d-inline-flex">
              <div class="ml-auto flex">
                <a [routerLink]="['home']" routerLinkActive="active">
                  Home
                </a>
              </div>
              <div class="flex">
                <a [routerLink]="['about']" routerLinkActive="active" class="ms-4">
                  About
                </a>
              </div>
              <div class="flex ms-4">
                <a [routerLink]="['shop']" routerLinkActive="active">
                  Shop Parts
                </a>
              </div>
              <div class="flex ms-4">
                <a [routerLink]="['sell']" routerLinkActive="active">
                  Sell
                </a>
              </div>
              <div class="flex ms-4">
                <a [routerLink]="['blog']" routerLinkActive="active">
                  Blog
                </a>
              </div>
              <div class="flex ms-4">
                <a *ngIf="currentUser" [routerLink]="['account']" routerLinkActive="active">
                  Account
                </a>
              </div>
              <div class="flex ms-4">
                <a [routerLink]="['contact']" routerLinkActive="active">
                  Contact
                </a>
              </div>
              <div class="flex ms-4">|</div>
              <div class="flex ms-4">
                <a href="https://www.facebook.com/ihmusedparts" target="_blank">
                  <fa-icon [icon]="['fab', 'facebook-f']" size="lg" class="text-white"></fa-icon>
                </a>
              </div>
            </div>
          </div>
          <div class="col-12 mt-3 text-right pr-0 mr-0">
            <p class="small-footer-text">Copyright © {{ today | date: 'yyyy'}} IHM Used Parts. All Rights Reserved. | <a
                [routerLink]="['privacy-policy']" routerLinkActive="active">Privacy Policy</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container d-block d-md-none">
    <div class="row">
      <div class="col-12 text-center mt-5 mb-5">
        <a [routerLink]="['home']">
          <img class="img img-fluid footer-logo" loading="lazy" src="assets/images/logo-ihm-reverse@2x.webp"
            alt="IHM Used Parts Footer Logo" width="130px" height="48px" />
        </a>
      </div>
      <div class="col-12 mb-4">
        <div class="row">
          <div class="col-6 d-flex flex-column">
            <a [routerLink]="['home']" routerLinkActive="active">
              Home
            </a>
            <a [routerLink]="['about']" routerLinkActive="active">
              About
            </a>
            <a [routerLink]="['shop']" routerLinkActive="active">
              Shop Parts
            </a>
          </div>
          <div class="col-6 d-flex flex-column">
            <a [routerLink]="['sell']" routerLinkActive="active">
              Sell
            </a>
            <a [routerLink]="['blog']" routerLinkActive="active">
              Blog
            </a>
            <a *ngIf="currentUser" [routerLink]="['account']" routerLinkActive="active">
              Account
            </a>
            <a [routerLink]="['contact']" routerLinkActive="active">
              Contact
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 mt-3 d-inline-flex">
        <p class="flex small-footer-text">Copyright © {{ today | date: 'yyyy'}} IHM Used Parts.<br> All Rights Reserved.
          | <a [routerLink]="['privacy-policy']" routerLinkActive="active">Privacy Policy</a></p>
        <a href="https://www.facebook.com/ihmusedparts" target="_blank" class="flex ms-auto">
          <fa-icon [icon]="['fab', 'facebook-f']" size="2x" class="text-white"></fa-icon>
        </a>
      </div>
    </div>
  </div>
</footer>
