export class SearchObject {
  searchValue: string
  newSurplus: boolean
  used: boolean
  newAftermarket: boolean
  reManufactured: boolean
  performSearch: boolean
  clearSearch: boolean
  manufacturer: string
  partNumber: string
  machineType: string
  sortBy: string
  page: number
  take: number
  constructor({
    searchValue = '',
    newSurplus = false,
    used = false,
    newAftermarket = false,
    reManufactured = false,
    performSearch = false,
    clearSearch = false,
    manufacturer = '',
    partNumber = '',
    machineType = '',
    sortBy = '',
    page = 1,
    take = 10,
    ...rest
  }) {
    Object.assign(this, rest)
    this.searchValue = searchValue
    this.newSurplus = newSurplus
    this.used = used
    this.newAftermarket = newAftermarket
    this.reManufactured = reManufactured
    this.performSearch = performSearch
    this.clearSearch = clearSearch
    this.manufacturer = manufacturer
    this.partNumber = partNumber
    this.machineType = machineType
    this.sortBy = sortBy
    this.page = page
    this.take = take
  }
}
