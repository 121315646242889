import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialShareComponent } from './social-share.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareButtonsConfig } from 'ngx-sharebuttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons'
const customConfig: ShareButtonsConfig = {
  include: ['facebook', 'twitter', 'google', 'copyLink' ],
  exclude: ['tumblr', 'stumble', 'vk'],
  theme: 'modern-light',
  gaTracking: true,
  twitterAccount: 'twitterUsername'
}


@NgModule({
  declarations: [
    SocialShareComponent
  ],
  imports: [
    CommonModule,
    ShareButtonsModule.withConfig(customConfig),
    ShareIconsModule
  ],
  exports: [
    SocialShareComponent
  ]
})
export class SocialShareModule { }
