import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BaseModel } from '../shared/models/base-model';
import { Cart } from '../shared/models/cart';
import { LocalStorageService } from '../shared/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  apiUrl: string
  orderApi: string
  // TODO: Model out the Cart
  private currentCartSubject: BehaviorSubject<any>
  public currentCart: Observable<any>
  constructor(
    private http: HttpClient,
    private storage: LocalStorageService
  ) {
    this.apiUrl = `${environment.apiUrl}/carts`
    this.orderApi = `${environment.apiUrl}/orders`
    this.currentCartSubject = new BehaviorSubject<any>(this.storage.getItem('currentCart'))
    this.currentCart = this.currentCartSubject.asObservable()
  }

  public get currentCartValue(): Cart {
    return this.currentCartSubject.value
  }

  removeCurrentCart() {
    this.storage.setItem('currentCart', undefined)
    this.storage.removeItem('currentCart')
    this.currentCartSubject.next(null)
  }

  setCurrentCart(cart: any) {
    this.storage.setItem('currentCart', cart)
    this.currentCartSubject.next(cart)
  }

  getCart(params) {
    return this.http.get<any>(`${this.apiUrl}/get_cart?id=${params.id}`).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res)
    }))
  }

  createCart(params) {
    return this.http.post<any>(`${this.apiUrl}/create`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res)
    }))
  }

  editCart(params: any) {
    return this.http.post<any>(`${this.apiUrl}/edit`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res)
    }))
  }

  assignUserToCart(params: any) {
    return this.http.post<any>(`${this.apiUrl}/assign_user_to_cart`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res)
    }))
  }

  addPartToCart(params: any) {
    return this.http.post<any>(`${this.apiUrl}/add_to_cart`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res)
    }))
  }

  addCountToPart(params: any) {
    return this.http.post<any>(`${this.apiUrl}/add_count_to_part`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res)
    }))
  }

  subtractCountFromPart(params: any) {
    return this.http.post<any>(`${this.apiUrl}/subtract_count_from_part`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res)
    }))
  }

  removePartFromCart(params: any) {
    return this.http.post<any>(`${this.apiUrl}/remove_from_cart`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res)
    }))
  }

  clearCart(params: any) {
    return this.http.post<any>(`${this.apiUrl}/clear_cart`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res)
    }))
  }


  deleteCart(params: any) {
    return this.http.post<any>(`${this.apiUrl}/delete`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res)
    }))
  }

  calculateShipping(params: any) {
    return this.http.post<any>(`${this.apiUrl}/calculate_shipping`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        if (res.success) {
          this.storage.setItem('currentCart', undefined)
          this.storage.removeItem('currentCart')
          this.currentCartSubject.next(null)
        }
        return this.handleBaseResponse(res)
      }
    ))
  }

  placeOrder(params: any){
    return this.http.post<any>(`${this.orderApi}/create`, params).pipe(
      catchError(this.handlePlaceOrderError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res)
      }
    ))
  }

  placeGuestOrder(params: any) {
    return this.http.post<any>(`${this.orderApi}/create_guest_order`, params).pipe(
      catchError(this.handlePlaceOrderError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res)
      }
    ))
  }

  private handleBaseResponse(res: BaseModel) {
    if (res.success) {
      return res.payload
    } else {
      throwError(res.errors)
      return res
    }
  }

  private handleError(error) {
    let errorMessage = ''
    // server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error['error']['errors']}`
    return throwError({ status: error.status, msg: errorMessage })
  }

  private handlePlaceOrderError(error) {
    let errorMessage = ''
    if (error['error']['errors']['errors']) {
      errorMessage = error['error']['errors']['errors'][1]['errorMsg']
    }
    return throwError({status: error.status, msg: errorMessage})
  }


}
