import { SearchObject } from './../models/search-object';
import { LocalStorageService } from './local-storage.service';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { BaseModel } from '../models/base-model';

@Injectable({
  providedIn: 'root'
})
export class CurrentSearchService {
  private brandsUrl: string
  private currentSearchSubject: BehaviorSubject<SearchObject>
  public currentSearch: Observable<SearchObject>
  constructor(
    private http: HttpClient,
    private storage: LocalStorageService
  ) {
    this.currentSearchSubject = new BehaviorSubject<SearchObject>(this.storage.getItem('currentSearch'))
    this.currentSearch = this.currentSearchSubject.asObservable()
    this.brandsUrl = `${environment.apiUrl}/brands`
  }

  public get currentSearchValue(): SearchObject {
    return this.currentSearchSubject.value
  }

  setCurrentSearch(search: SearchObject) {
    this.storage.setItem('currentSearch', search)
    this.currentSearchSubject.next(search)
  }

  removeCurrentSearch() {
    this.storage.setItem('currentSearch', undefined)
    this.storage.removeItem('currentSearch')
    this.currentSearchSubject.next(null)
  }

  getBrowseByBrands() {
    return this.http.get<any>(`${this.brandsUrl}/get_browse_by_brands`).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res)
      }
      ))
  }

  getBrands() {
    return this.http.get<any>(`${this.brandsUrl}/get_brands`).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res)
      }
      )
    )
  }

  getBrandParts(params) {
    return this.http.get<any>(`${this.brandsUrl}/get_brand_parts`, { params: params }).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res)
      }
      )
    )
  }

  getPartsByBrand(params) {
    return this.http.post<any>(`${this.brandsUrl}/get_parts_by_brand`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res)
      }
      )
    )
  }

  returnBrandPart(params) {
    return this.http.post<any>(`${this.brandsUrl}/return_brand_part`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res)
      }
      )
    )
  }

  private handleBaseResponse(res: BaseModel) {
    if (res.success) {
      return res.payload
    } else {
      throwError(res.errors)
      return res
    }
  }

  private handleError(error) {
    let errorMessage = ''
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.errors}`
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error['error']['errors']}`
    }
    return throwError({ status: error.status, msg: errorMessage })
  }

}
