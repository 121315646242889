import { isPlatformBrowser } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { ErrorHandler, Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class IhmErrorHandler implements ErrorHandler {
  apiUrl = `${environment.apiUrl}/super_admin/error_logs`
  constructor(@Inject(PLATFORM_ID) private platformId: any, private http: HttpClient) {
  }

  handleError(error: any): void {
    if (isPlatformBrowser(this.platformId)) {
      this.handleBrowser(error)
    } else {
      this.handleServer(error)
    }
  }

  handleBrowser(error: any) {
    const params = { type: 'BrowserError', error: error }
    this.http.post<any>(`${this.apiUrl}/create_error_log`, params)
  }

  handleServer(error: any) {
    const params = { type: 'ServerError', error: error }
    this.http.post<any>(`${this.apiUrl}/create_error_log`, params)
  }
}
