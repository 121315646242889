import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService {

  constructor(private userService: UserService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      let found
      const apiUrl = environment.apiUrl
      const blackListedRoutes = [
        `${apiUrl}/api/v1/users/login`,
        `${apiUrl}/api/v1/users/logout`,
        `${apiUrl}/api/v1/orders/calculate_shipping`,
        `${apiUrl}/api/v1/orders/create`,
        `${apiUrl}/api/v1/orders/edit`,
        `${apiUrl}/api/v1/orders/get_order_by_id`,
        `${apiUrl}/api/v1/orders/create_address`,
        `${apiUrl}/api/v1/accounts/get_account`
      ]
      for (let i = 0; i < blackListedRoutes.length; i++) {
        if (blackListedRoutes[i] === request['url']) {
          found = true;
          break;
        } else {
          found = false
        }
      }
      if (found === false) {

        if (err.status === 401) {
          // auto logout if 412 response returned from api
          this.userService.removeCurrentUserAndRoute401();
        }
      }
      const error = err || err.statusText;
      return throwError(error);
    }))
  }
}
