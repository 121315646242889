<div class="social-share" *ngIf="showSmallShare" [ngClass]="hideMobileShare ? 'd-none' : 'd-md-none'">
  <share-buttons [image]="image" [title]="title" [description]="description" [url]="url" [theme]="'default'"
    [include]="['facebook', 'twitter', 'pinterest', 'copy']" [show]="5" [autoSetMeta]="false">
  </share-buttons>
</div>
<div class="social-share d-none d-md-block" *ngIf="showShare">
  <share-buttons [image]="image" [title]="title" [description]="description" [url]="url" [theme]="'default'"
    [include]="['facebook', 'twitter', 'pinterest', 'copy']" [show]="5" [autoSetMeta]="false">
  </share-buttons>
</div>
