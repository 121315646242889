export class User {
  id: number
  first_name: string
  last_name: string
  email: string
  phone: string
  role: string
  removed: boolean
  customer_id: string
  user_type: string
  purchase_order_enabled: boolean
  constructor({
    id = 0,
    first_name = '',
    last_name = '',
    email = '',
    phone = '',
    removed = false,
    customer_id = '',
    user_type = '',
    purchase_order_enabled = false,
    ...rest
  }) {
    Object.assign(this, rest)
    this.id = id
    this.first_name = first_name
    this.last_name = last_name
    this.email = email
    this.phone = phone
    this.role = this.mapForUserRole(rest.role)
    this.removed = removed
    this.customer_id = customer_id
    this.user_type = user_type,
    this.purchase_order_enabled = purchase_order_enabled
  }

  mapForUserRole(id: number) {
    switch(id) {
      case 1: {
        return 'user'
      }
      case 2: {
        return 'disc_user'
      }
      case 3: {
        return 'admin'
      }
      case 4: {
        return 'super_admin'
      }
      default: {
        return 'user'
      }
    }
  }
}
