import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SearchObject } from 'src/app/shared/models/search-object';
import { CurrentSearchService } from 'src/app/shared/services/current-search.service';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup
  formValues: any
  submitting = false
  @Output() closeSearch: EventEmitter<boolean> = new EventEmitter()
  private subs = new Subscription()
  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private csService: CurrentSearchService
  ) { }

  ngOnInit(): void {
    this.createFormValues()
    this.createForm()
    this.subToCurrentSearch()
  }

  createFormValues() {
    this.formValues = {
      searchValue: [''],
      newSurplus: [false],
      used: [false],
      newAftermarket: [false],
      reManufactured: [false]
    }
  }

  createForm() {
    this.form = this.fb.group(this.formValues)
  }

  subToCurrentSearch() {
    this.subs.add(
      this.csService.currentSearch.subscribe(search => {
        if (search) {
          this.setFormValues(search)
        }
      })
    )
  }

  setFormValues(search: SearchObject) {
    this.form.get('searchValue').setValue(search.searchValue)
    this.form.get('newSurplus').setValue(search.newSurplus)
    this.form.get('used').setValue(search.used)
    this.form.get('newAftermarket').setValue(search.newAftermarket)
    this.form.get('reManufactured').setValue(search.reManufactured)
  }

  submitForm() {
    this.submitting = true
    if (!this.form.valid) {
      this.submitting = false
      return
    }
    let params = new SearchObject(this.form.value)
    params.sortBy = 'Price (low to high)'
    params.page = 1
    params.take = 10
    params.performSearch = true
    if (this.router.url === '/shop/search') {
      this.csService.setCurrentSearch(params)
      this.submitting = false
      this.closeSearch.emit(true)
    } else {
      this.csService.setCurrentSearch(params)
      this.closeSearch.emit(true)
      this.submitting = false
      this.router.navigate(['/shop/search'])
    }
  }

  setOtherTypesToFalse(control: string) {
    this.form.get('newSurplus').setValue(false)
    this.form.get('newAftermarket').setValue(false)
    this.form.get('used').setValue(false)
    this.form.get('reManufactured').setValue(false)
    this.form.get(control).setValue(true)
  }

  ngOnDestroy() {
    this.form.reset()
    this.subs.unsubscribe()
  }

}
