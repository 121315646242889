import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { MetaTagsService } from '../shared/services/meta-tags.service';

@Component({
  selector: 'app-four04',
  templateUrl: './four04.component.html',
  styleUrls: ['./four04.component.scss']
})
export class Four04Component implements OnInit {

  constructor(
    private tsService: Title,
    private metaService: Meta,
    private tagsService: MetaTagsService
  ) {
  }

  ngOnInit(): void {
    this.setUpSeo()
  }

  setUpSeo() {
    const title = 'IHM Used Parts | Page Not Found}'
    this.tsService.setTitle(title)
    const tags = [
      { property: 'og:image', content: `${environment.webUrl}/assets/images/ihm-used-parts-og.png` },
      { property: 'og:title', content: title },
      { property: 'og:type', content: 'website' },
      { property: 'og:description', content: environment.webDescription },
      { property: 'description', content: environment.webDescription }
    ]
    tags.forEach(x => {
      this.metaService.updateTag(x)
    })
    this.tagsService.setCurrentTags(tags)
  }

}
