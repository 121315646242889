import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CartService } from 'src/app/cart/cart.service';
import { environment } from 'src/environments/environment';
import { BaseModel } from '../models/base-model';
import { User } from '../models/user';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  apiUrl: string
  private currentUserSubject: BehaviorSubject<User>
  public currentUser: Observable<User>
  notificationsApi: string
  constructor(
    private router: Router,
    private http: HttpClient,
    private cartService: CartService,
    private storage: LocalStorageService
  ) {
    this.apiUrl = `${environment.apiUrl}/users`
    this.currentUserSubject = new BehaviorSubject<User>(this.storage.getItem('currentUser'))
    this.currentUser = this.currentUserSubject.asObservable()
    this.notificationsApi = `${environment.apiUrl}/notifications`
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value
  }

  setCurrentUser(user: User) {
    this.currentUserSubject.next(user)
  }

  subscribeToMailingList(params: any): Observable<BaseModel> {
    return this.http.post(`${this.apiUrl}/subscribe_to_mailing_list`, params)
    .pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res)
    }))
  }
  // takes :first_name, :last_name, :phone
  forgotUserName(params) {
    return this.http.post(`${this.apiUrl}/forgot_username`, params)
    .pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res)
    }))
  }
  // takes :email
  sendPasswordResetEmail(params) {
    return this.http.post(`${this.apiUrl}/send_password_reset_email`, params)
    .pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res)
    }))
  }

  // takes :token
  verfiyPasswordResetToken(params) {
    return this.http.post(`${this.apiUrl}/verify_password_reset_token`, params)
    .pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res)
    }))
  }

  // takes :token, :email, and :password
  resetPassword(params) {
    return this.http.post(`${this.apiUrl}/reset_password`, params)
    .pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleSuccessfulResponse(res)
    }))
  }

  verifyEmail(params: any) {
    return this.http.post(`${this.apiUrl}/verify_email`, params)
    .pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res)
    }))
  }

  resendVerificationEmail(params) {
    return this.http.post(`${this.apiUrl}/resend_verification_email`, params)
    .pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res)
    }))
  }

  contactUs(params: any): Observable<BaseModel> {
    return this.http.post(`${this.apiUrl}/contact_us`, params)
    .pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res)
    }))
  }

  login(params: any) {
    return this.http.post(`${this.apiUrl}/login`, params)
    .pipe(
      catchError(this.handleError),
      map(res => {
        return this.handleSuccessfulResponse(res)
    }))
  }

  signUp(params) {
    return this.http.post<any>(`${this.apiUrl}/create`, params)
    .pipe(
      catchError(this.handleError),
      map(res => {
        return this.handleSuccessfulResponse(res)
    }))
  }

  logout() {
    return this.http.delete<any>(`${this.apiUrl}/logout`, {})
  }


  logoutUser() {
    this.logout().subscribe(data => {
      // logout successful
      if (data) {
        this.removeCurrentUserAndRoute()
      } else {
        // logout unsuccesful, but we still want to remove the currentUser and accessToken
        this.removeCurrentUserAndRoute()
      }
    }, error => {
      if (error) {
        this.removeCurrentUserAndRoute()
      }
    });
  }

  removeCurrentUserAndRoute401() {
    this.storage.setItem('currentUser', undefined)
    this.storage.setItem('accessToken', undefined)
    this.storage.setItem('currentSearch', undefined)
    this.storage.removeItem('currentUser')
    this.storage.removeItem('accessToken')
    this.storage.removeItem('currentSearch')
    this.currentUserSubject.next(null)
    const cart = this.cartService.currentCartValue
    if (cart) {
      this.cartService.deleteCart({ id: cart.id }).subscribe(data => {
        if (data) {
          this.cartService.removeCurrentCart()
          this.router.navigate(['/login', { login: true }])
        }
        return true
      }, error => {
        if (error) {
          this.cartService.removeCurrentCart()
          this.router.navigate(['/login', { login: true }])
        } else {
          this.cartService.removeCurrentCart()
          this.router.navigate(['/login', { login: true }])
        }
        return true
      })
    } else {
      this.router.navigate(['/login', { login: true }])
      return true
    }
  }

  removeCurrentUserAndRoute() {
    this.storage.setItem('currentUser', undefined)
    this.storage.setItem('accessToken', undefined)
    this.storage.setItem('currentSearch', undefined)
    this.storage.removeItem('currentUser')
    this.storage.removeItem('accessToken')
    this.storage.removeItem('currentSearch')
    this.currentUserSubject.next(null)
    const cart = this.cartService.currentCartValue
    if (cart) {
      this.cartService.deleteCart({ id: cart.id }).subscribe(data => {
        if (data) {
          this.cartService.removeCurrentCart()
          this.router.navigate(['/login', { success: true }])
        }
        return true
      }, error => {
        if (error) {
          this.cartService.removeCurrentCart()
          this.router.navigate(['/login', { success: true }])
        } else {
          this.cartService.removeCurrentCart()
          this.router.navigate(['/login', { success: true }])
        }
        return true
      })
    } else {
      this.router.navigate(['/login', { success: true }])
      return true
    }
  }

  // Beginning of Private Methods
  private handleSuccessfulResponse(res) {
    // login successful if there's a success, currentUser, and securityToken in the response
    if (res && res.success && res.payload.user.token) {
      const newUser = new User(res.payload.user)
      this.currentUserSubject.next(newUser)
      const token = res.payload.user.token
      this.storage.setItem('accessToken', token.value)
      this.storage.setItem('currentUser', newUser)
      return { success: true, user: newUser, cart: res.payload.cart }
    } else if (res && !res.success && res.message) {
      return { success: false, errorMsg: res.message }
    } else {
      return { success: false }
    }
  }

  private handleBaseResponse(res: BaseModel) {
    if (res.success) {
      return res.payload
    } else {
      throwError(res.errors)
      return res
    }
  }

  private handleError(error) {
    let errorMessage = ''
    // server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error['error']['errors']}`
    return throwError({ status: error.status, msg: errorMessage })
  }
}
