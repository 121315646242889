
import { throwError as observableThrowError, Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service'
import { Router } from '@angular/router'
import { Injectable } from '@angular/core'
import { HttpClient, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http'
import { environment } from '../../../environments/environment';
// import { ModalService } from './modal.service';



@Injectable({
  providedIn: 'root'
})
export class AuthorizationHeaderService implements HttpInterceptor {

  constructor(
    private router: Router,
    private storage: LocalStorageService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const apiUrl = environment.apiUrl
    const blackListedRoutes = [
      // `${apiUrl}/api/v1/Booking/completeRecentBookings`,
      `${apiUrl}/api/v1/Security/refresh`,
      `${apiUrl}/api/v1/Security/login`,
      `${apiUrl}/api/v1/Security/register`,
      'https://api.cosmicjs.com/v1/outside360-dev/field-guide',
      'https://api.cosmicjs.com/v1/outside360-dev'
    ]

    const token = this.storage.getItem('accessToken')

    const user = this.storage.getItem('currentUser')

    const aliasToken = this.storage.getItem('aliasToken')

    const aliasUser = this.storage.getItem('aliasUser')

    let found = false;

    for (let i = 0; i < blackListedRoutes.length; i++) {
      if (blackListedRoutes[i] === req.url) {
        found = true;
        break;
      }
    }

    if (aliasUser && aliasToken && found === false) {
      const authReq = req.clone({ setHeaders: { Authorization: 'Bearer ' + aliasToken } });
      return next.handle(authReq)
    } else if (user && token && found === false) {
      const authReq = req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
      return next.handle(authReq)
    } else {
      return next.handle(req)
    }

}

}
