import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule, FaIconLibrary, FaIconComponent, FaStackComponent } from '@fortawesome/angular-fontawesome';
import {
  faShoppingCart, faSpinner, faAngleLeft, faAngleRight, faPlus, faMinus, faUpload,
  faEdit, faTrashAlt, faSearch, faEye, faEyeSlash, faSignInAlt, faSignOutAlt,
  faCircle, faBars, faWindowClose,faTimes,
  faTrash, faCog, faCode,
  faPhone, faPlay, faCalendarAlt, faAngleDown, faPlusSquare, faMinusSquare, faUserCircle, faHome, faBlog, faReceipt, faWarehouse, faPallet, faUsers, faPencilAlt,
  faExclamationCircle,
  faFileAlt
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF, faYoutube, faYoutubeSquare
} from '@fortawesome/free-brands-svg-icons'
import { faStar as farStar, faCircle as farCircle, faWindowMaximize } from '@fortawesome/free-regular-svg-icons';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        FontAwesomeModule
    ],
    exports: [
        FaIconComponent,
        FaStackComponent
    ]
})
export class FaIconModule {
  constructor(
    private library: FaIconLibrary
  ) {
    this.library.addIcons(
      faSpinner, faFacebookF, faCalendarAlt, faUpload, faPlus, faMinus, faPlusSquare, faMinusSquare, faYoutubeSquare, faYoutube, faSearch,
      faPlay, faShoppingCart, faCog, faPhone, faSignInAlt, faSignOutAlt, faBars, faWindowClose, faEye, faHome, faBlog, faReceipt, faWarehouse,
      faPallet, faUsers, faEyeSlash, faCircle, faAngleLeft, faAngleRight, faAngleDown, faTrash, faTrashAlt, faTimes, faUserCircle, faEdit, faWindowMaximize,
      faPencilAlt, faExclamationCircle, faFileAlt, faCode
      )
  }
}
