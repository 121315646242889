<div class="app app-container">
  <app-navbar [dashboard]="dashboard"></app-navbar>
  <main style="min-height: 90vh;">
    <div class="fluid-container pt-md-0 pb-lg-0 pb-md-0 pb-0">
      <router-outlet></router-outlet>
      <app-social-share class="social-share"></app-social-share>
    </div>
  </main>
  <footer class="footer w-100 pb-0 pt-0">
    <app-footer style="margin: 0px auto;"></app-footer>
  </footer>
</div>
