import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Four04Component } from './four04/four04.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path: '', pathMatch: 'full', loadChildren: () => import('./home/home.module')
      .then(mod => mod.HomeModule)
  },
  {
    path: 'home', pathMatch: 'full', loadChildren: () => import('./home/home.module')
      .then(mod => mod.HomeModule)
  },
  // Auth Routes
  {
    path: 'login', loadChildren: () => import('./auth/login/login.module')
      .then(mod => mod.LoginModule)
  },
  {
    path: 'signup', loadChildren: () => import('./auth/signup/signup.module')
      .then(mod => mod.SignupModule)
  },
  {
    path: 'forgot-username', loadChildren: () => import('./auth/forgot-username/forgot-username.module')
      .then(mod => mod.ForgotUsernameModule)
  },
  {
    path: 'forgot-password', loadChildren: () => import('./auth/forgot-password/forgot-password.module')
      .then(mod => mod.ForgotPasswordModule)
  },
  {
    path: 'reset-password', loadChildren: () => import('./auth/reset-password/reset-password.module')
      .then(mod => mod.ResetPasswordModule)
  },
  {
    path: 'verify-email', loadChildren: () => import('./auth/verify-email/verify-email.module')
      .then(mod => mod.VerifyEmailModule)
  },
  // End of Auth Routes
  // Single Level Routes
  {
    path: 'about', loadChildren: () => import('./about/about.module')
      .then(mod => mod.AboutModule)
  },
  {
    path: 'account', loadChildren: () => import('./account/account.module')
      .then(mod => mod.AccountModule)
  },
  {
    path: 'checkout', loadChildren: () => import('./checkout/checkout.module')
      .then(mod => mod.CheckoutModule)
  },
  {
    path: 'contact', loadChildren: () => import('./contact/contact.module')
      .then(mod => mod.ContactModule)
  },
  {
    path: 'order-confirmation', loadChildren: () => import('./order-confirmation/order-confirmation.module')
      .then(mod => mod.OrderConfirmationModule)
  },
  {
    path: 'checkout', loadChildren: () => import('./checkout/checkout.module')
      .then(mod => mod.CheckoutModule)
  },
  {
    path: 'sell', loadChildren: () => import('./sell/sell.module')
      .then(mod => mod.SellModule)
  },
  {
    path: 'notifications', loadChildren: () => import('./notifications/notifications.module')
      .then(mod => mod.NotificationsModule)
  },
  // end of Single Level Routes
  // Nested Level Routes
  {
    path: 'excavator-parts', loadChildren: () => import('./excavator-parts/excavator-parts.module')
      .then(mod => mod.ExcavatorPartsModule)
  },
  {
    path: 'blog', loadChildren: () => import('./blog/blog.module')
      .then(mod => mod.BlogModule)
  },
  {
    path: 'cart', loadChildren: () => import('./cart/cart.module')
      .then(mod => mod.CartModule)
  },
  {
    path: 'shop', loadChildren: () => import('./shop/shop.module')
      .then(mod => mod.ShopModule)
  },
  {
    path: 'category', loadChildren: () => import('./shop-category/shop-category.module')
      .then(mod => mod.ShopCategoryModule)
  },
  {
    path: 'quote', pathMatch: 'full', loadChildren: () => import('./quote/quote.module')
      .then(mod => mod.QuoteModule)
  },
  {
    path: 'privacy-policy', loadChildren: () => import('./privacy-policy/privacy-policy.module')
      .then(mod => mod.PrivacyPolicyModule)
  },
  {
    path: 'product/:partId', pathMatch: 'full', loadChildren: () => import('./product/product.module')
      .then(mod => mod.ProductModule)
  },
  {
    path: 'product/:partId/', pathMatch: 'full', loadChildren: () => import('./product/product.module')
      .then(mod => mod.ProductModule)
  },
  // End of Nested Level Routes
  // Customer Support & Super Admin
  {
    path: 'customer-support', loadChildren: () => import('./dashboards/cs-dashboard/cs-dashboard.module')
      .then(mod => mod.CsDashboardModule)
  },
  {
    path: 'super-admin', loadChildren: () => import('./dashboards/sa-dashboard/sa-dashboard.module')
      .then(mod => mod.SaDashboardModule)
  },
  // End of CS & SA
  // Old Pages Routes
  // {
  //   path: '1-2', pathMatch: 'full', loadChildren: () => import('./old-pages/old-pages.module')
  //     .then(mod => mod.OldPagesModule)
  // },
  // {
  //   path: '2-2', pathMatch: 'full', loadChildren: () => import('./old-pages/old-pages.module')
  //     .then(mod => mod.OldPagesModule)
  // },
  // {
  //   path: '3-2', pathMatch: 'full', loadChildren: () => import('./old-pages/old-pages.module')
  //     .then(mod => mod.OldPagesModule)
  // },
  // {
  //   path: '4-2', pathMatch: 'full', loadChildren: () => import('./old-pages/old-pages.module')
  //     .then(mod => mod.OldPagesModule)
  // },
  // {
  //   path: '5-2', pathMatch: 'full', loadChildren: () => import('./old-pages/old-pages.module')
  //     .then(mod => mod.OldPagesModule)
  // },
  // {
  //   path: '6-2', pathMatch: 'full', loadChildren: () => import('./old-pages/old-pages.module')
  //     .then(mod => mod.OldPagesModule)
  // },
  // {
  //   path: '7-2', pathMatch: 'full', loadChildren: () => import('./old-pages/old-pages.module')
  //     .then(mod => mod.OldPagesModule)
  // },
  // {
  //   path: '8-2', pathMatch: 'full', loadChildren: () => import('./old-pages/old-pages.module')
  //     .then(mod => mod.OldPagesModule)
  // },
  // {
  //   path: '9-2', pathMatch: 'full', loadChildren: () => import('./old-pages/old-pages.module')
  //     .then(mod => mod.OldPagesModule)
  // },
  // {
  //   path: '10-2', pathMatch: 'full', loadChildren: () => import('./old-pages/old-pages.module')
  //     .then(mod => mod.OldPagesModule)
  // },
  // {
  //   path: '11-2', pathMatch: 'full', loadChildren: () => import('./old-pages/old-pages.module')
  //     .then(mod => mod.OldPagesModule)
  // },
  // {
  //   path: '12-2', pathMatch: 'full', loadChildren: () => import('./old-pages/old-pages.module')
  //     .then(mod => mod.OldPagesModule)
  // },
  // {
  //   path: '13-2', pathMatch: 'full', loadChildren: () => import('./old-pages/old-pages.module')
  //     .then(mod => mod.OldPagesModule)
  // },
  // {
  //   path: '14-2', pathMatch: 'full', loadChildren: () => import('./old-pages/old-pages.module')
  //     .then(mod => mod.OldPagesModule)
  // },
  // {
  //   path: '15-2', pathMatch: 'full', loadChildren: () => import('./old-pages/old-pages.module')
  //     .then(mod => mod.OldPagesModule)
  // },
  // {
  //   path: '16-2', pathMatch: 'full', loadChildren: () => import('./old-pages/old-pages.module')
  //     .then(mod => mod.OldPagesModule)
  // },
  // {
  //   path: '17-2', pathMatch: 'full', loadChildren: () => import('./old-pages/old-pages.module')
  //     .then(mod => mod.OldPagesModule)
  // },
  // {
  //   path: '18-2', pathMatch: 'full', loadChildren: () => import('./old-pages/old-pages.module')
  //     .then(mod => mod.OldPagesModule)
  // },
  // // End of Old Pages Routes
  { path: '**', component: Four04Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
